import { ApplicationConfig, isDevMode } from "@angular/core";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { authHttpInterceptorFn, provideAuth0 } from "@auth0/auth0-angular";
import { provideAnimations } from "@angular/platform-browser/animations";

import { routes } from "./app.routes";
import { provideServiceWorker } from "@angular/service-worker";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideToastr } from 'ngx-toastr';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const appConfig: ApplicationConfig = {
	providers: [
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
		provideAnimations(),
		provideToastr(),
		provideHttpClient(withInterceptors([authHttpInterceptorFn])),
		provideRouter(routes, withComponentInputBinding()),
		provideAuth0({
			domain: "yappy-admin.eu.auth0.com",
			clientId: "lwZSKyGxxdZredzDB4gB2YWMUnxCLbjl",
			authorizationParams: {
				redirect_uri: window.location.origin,
				audience: "https://yappy-admin.eu.auth0.com/api/v2/",
			},
			// domain: "yappydev.eu.auth0.com",
			// clientId: "ALcBDaYizxar8ZB6yxZrxQ1qqamKFXOr",
			// authorizationParams: {
			// 	redirect_uri: window.location.origin,
			// 	audience: "https://yappydev.eu.auth0.com/api/v2/",
			// },
			httpInterceptor: {
				allowedList: [
					"https://yappy-dev-clientmanagement.azurewebsites.net/clients",
				],
			},
			errorPath: "/error",
		}),
		provideServiceWorker("ngsw-worker.js", {
			enabled: !isDevMode(),
			registrationStrategy: "registerWhenStable:30000",
		}),
		provideAnimationsAsync(),
	],
};
